:root {
  --color-primary: #EFF4FF;
  --color-secondary: #0058A5;
}

// Imports
@import "bootstrap";


.dropdown.active {
  a.dropdown-item{
    color: $body-color!important;
    &.active{
      color: $white!important;
    }
  }
}

@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;500;600;700;800;900&display=swap');

@font-face {
  font-family: "MADE Dillan";
  src: url("../font/MADE_Dillan.otf");
}

@font-face {
  font-family: "Maximum Impact";
  src: url("../font/Maximum_Impact.ttf");
}

* {
  margin: 0;
  padding: 0;
}

*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

*::selection {
  background-color: var(--color-secondary);
  color: #fff !important;
}

html {
  font-family: 'Playfair Display', serif;
  font-size: 62.5% !important; // 1rem = 10px, 10px/16px = 62.5%
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  scroll-behavior: smooth;
  line-height: 1.5;
  color: #545454;
  width: 100%;
  overflow-x: hidden;
}

body {
  font-family: 'Playfair Display', serif;
  background-repeat: no-repeat;
  min-height: 100vh;
  max-width: 100%;
  overflow-x: hidden !important;
}

.navbar-nav{

  @media only screen and (max-width: 990px) {
    padding: 2rem 2rem !important;
  }
}

.Navbar {
  background: #fff !important;
  border-radius: 0px 0px 5rem 5rem !important;
  z-index: 10000 !important;
  transition: 1s all ease-in-out;
  width: 100%;
  max-width: 100%;
  position: absolute;
  top: 0;
  left: 0;

  @media only screen and (max-width: 990px) {
    border-radius: 0 !important;
  }

  &_image {
    margin-right: 5rem;
    margin-left: 5rem;

    &-img {
      max-width: 100% !important;
      height: 8.5rem;
      margin-left: 2rem;
      padding: .5rem 0;

      @media only screen and (max-width: 768px) {
        width: auto;
        height: 8vh;
        max-width: 100%;
        margin-top: 1rem !important;
        margin-bottom: 1rem;
      }
    }

  }

  &_nav {
    background-color: #fff !important;
    font-family: 'Playfair Display', serif;
    font-size: 1.3rem !important;
    display: flex !important;
    justify-content: space-between !important;
    transition: all 5s fade-in;
    padding: 0rem;
    width: 100%;
    max-width: 100%;

    &-item {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      max-width: 100%;

      @media only screen and (max-width: 768px) {
        justify-content: flex-start !important;
        align-items: flex-start !important;
        text-align: left;
      }
    }

    &-link {
      font-family: 'MADE Dillan' !important;
      font-style: normal !important;
      font-weight: 400 !important;
      font-size: 1.8rem !important;
      line-height: 1.2 !important;
      text-align: left !important;
      text-transform: none !important;
      color: var(--color-secondary) !important;
      white-space: nowrap !important;

      @media only screen and (max-width: 768px) {
        margin: .5rem 0 !important;
      }

      &:hover {
        color: #14AE9B !important;
        cursor: pointer !important;
      }
    }

    &-btn{
      font-family: 'Playfair Display', serif;
      font-size: 1.6rem;
      font-weight: 600;
      display: block;
      line-height: 1.3;
      text-transform: none;
      transition: all 0.4s;
      background: linear-gradient(90deg, #009E9B 0%, #14AE9B 31.25%, #28BD9C 53.65%, #47D59C 75.52%, #4FDB9C 90.63%, #5BE39C 100%);
      border-radius: 4rem;
      color: #fff;
      padding: 1.5rem !important;
      text-align: center;
      border: none;

      @media only screen and (max-width: 900px) {
        margin-left: 0 !important;
      }

      &:hover{
        text-decoration: none;
        padding: 1.5rem !important;
        background: var(--color-secondary);
        color: #fff;
        border: 1px solid var(--color-secondary);
        transition: all 1s;
      }
    }
  }
}

.collapse{
  background-color: #fff !important;
}

.navbar-active {
  position: fixed !important;
  width: 100%;
  max-width: 100%;
  background-color: #fff !important;
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.12);
  border: 0 !important;
  top: 0 !important;
  left: 0 !important;
  transition: 0.2s;
  z-index: 1000 !important;

  .navbar{
      height: 10vh;
  }
}

.clim-hide{
  width: 100%;
  max-width: 100%;
  background-color: red;
  position: absolute;
  top: 10rem;
  display: none;
  z-index: 100;
}


.slider{
  position: relative;
  background-image: url("/assets/img/slider.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  z-index: 0;

  &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: #000;
      opacity: 0.1;
      z-index: -1;
      transition: 0.5s;
  }

  &_content-home{
    padding: 13rem 0 0rem !important;
  }

  &_content, &_content-home{
    padding: 25rem 0 8rem;

    &-title{
      padding: 0 5rem;
      margin-top: -1rem !important;

      @media only screen and (max-width: 768px) {
        padding: 0;
      }
    }

    &-text{
      font-family: 'MADE Dillan';
      font-style: normal;
      font-weight: 400;
      font-size: 2.8rem;
      line-height: 1.7;
      text-align: center !important;
      color: #FFFFFF;
      padding-top: 4rem;
    }

    &-head{
      font-family: 'MADE Dillan';
      font-style: normal;
      font-weight: 400;
      font-size: 6.8rem;
      line-height: 1.7;
      text-align: center !important;
      color: #FFFFFF;

      @media only screen and (max-width: 768px) {
        font-size: 4.4rem;
      }
    }
  }

  &_bottom{
    width: 70% !important;
    background: rgba(239, 244, 255, 0.75);
    border-radius: 0px 7.2rem 0px 0px;

    @media only screen and (max-width: 1400px) {
      width: 100% !important;
    }

    &-content{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding-top: 6rem;
      padding-right: 4rem;
      padding-bottom: 6rem;
      padding-left: 10rem;

      @media only screen and (max-width: 990px) {
        padding-left: 0;
        padding-right: 0;
      }

      &--img{
        margin: auto;
        width: 100%;
        max-width: 100%;

        @media only screen and (max-width: 990px) {
          width: auto;
        }
      }

      &--title{
        font-family: 'Maximum Impact';
        font-style: normal;
        font-weight: 500;
        font-size: 2rem;
        line-height: 1.3;
        text-align: center !important;
        color: var(--color-secondary);
        margin-top: 2rem;
      }
    }
  }

  &_form{
	  padding: 4rem 2rem;
	  height: 100%;
  
	  &-label{
		font-family: 'Puritan';
		color: #fff;
		font-size: 1.2rem;
	  }
  
	  &-input{
		padding: 1rem;
		font-family: 'Puritan';
		font-style: normal;
		font-weight: 400;
		font-size: 2rem;
		line-height: 1.7;
		color: #E9C0A4;
		background: #FFFFFF;
		border-radius: .5rem;
		width: 100%;
		max-width: 100%;
		border: none;
		outline: none;
	  }
  
	  &-textarea{
		padding: 1rem;
		font-family: 'Puritan';
		font-style: normal;
		font-weight: 400;
		font-size: 2rem;
		line-height: 1.7;
		color: #E9C0A4;
		background: #FFFFFF;
		border-radius: .5rem;
		width: 100%;
		max-width: 100%;
		height: 100%;
		border: none;
		outline: none;
	  }
  
	  &-btn{
		color: #E9C0A4;
		text-align: justify;
		font-family: "Puritan";
		font-size: 2rem;
		font-style: normal;
		font-weight: 400;
		line-height: 3rem;
		background: #fff !important;
		padding: 1.5rem 4rem;
		border-radius: 2rem;
		display: flex;
		justify-content: center;
		align-items: center;
		border: none;
		outline: none;
	  }
	}
}

.climclass:hover .clim-hide{
  display: block;
  z-index: 100;
  cursor: pointer;
}

.sticky{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 60rem;
  right: 2rem;
  max-width: 100%;
  background: rgba(0, 158, 155, 0.51);
  border-radius: 3.5rem;
  padding: 1.5rem 0.5rem;
  z-index: 1000 !important;

  @media only screen and (max-width: 2600px) {
    top: 60rem;
  }

  @media only screen and (max-width: 2000px) {
    top: 40rem;
  }

  @media only screen and (max-width: 1600px) {
    top: 30rem;
  }

  @media only screen and (max-width: 1400px) {
    top: 40rem;
  }

  @media only screen and (max-width: 1200px) {
    top: 50rem;
  }

  &_top{
    display: flex;
    padding: 1rem 0;

    &-icon{
      margin-right: .5rem;
     
    &--img{
      width: 2.5rem;
      max-width: 100%;
      height: 2.5rem;
    }

    &--img2{
      width: 2.5rem;
      max-width: 100%;
      height: 2.5rem;
      position: relative;
      left: 20px;
      top: -5px;
    }
  }

    &-content{

      &--p{
        font-family: 'Maximum Impact';
        font-style: normal;
        font-weight: 400;
        font-size: 1.5rem;
        line-height: 1.3;
        text-align: center;
        color: #FFFFFF;
      }

      &--btn{
        font-family: 'Playfair Display', serif;
        font-size: 1.2rem;
        font-weight: 600;
        display: block;
        line-height: 1.3;
        text-transform: none;
        transition: all 0.4s;
        background: linear-gradient(90deg, #009E9B 0%, #14AE9B 31.25%, #28BD9C 53.65%, #47D59C 75.52%, #4FDB9C 90.63%, #5BE39C 100%);
        border-radius: 2rem;
        color: #fff;
        padding: 1.3rem 1rem !important;
        text-align: center;
        border: none;
        position: relative;
        left: -16px;

        &:hover{
          transition: all .5s;
          background: var(--color-secondary);
          text-decoration: none;
          color: #fff;
          cursor: pointer;
        }
      }
    }
  }

  &_divider{
    border: .7px solid #EFF4FF;
    margin: 1rem 0;
    width: 100%;
    max-width: 100%;
  }
}

.menu{
  background-color: #EFF4FF;
  padding: 10rem 0;

  &_image{

    &-img{
      width: 100%;
      max-width: 100%;
      border-radius: 2rem;
    }

    &-icon{
      margin-top: 1rem;
      margin-left: 1rem;
    }
  }

  &_content{

    &-p{
      font-family: 'Playfair Display';
      font-style: normal;
      font-weight: 400;
      font-size: 1.7rem;
      line-height: 1.2;
      color: #0058A5;
    }
  }
}

.stripe{
  position: relative;
  padding-top: 7rem;
  padding-bottom: 7rem;

  &-2 {
    background-image: url("/assets/img/gradient-bg.png");
    background-position: center top;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    width: 100%;
    max-width: 100%;
    padding: 10rem 0;

    .stripe {

      &_right2 {
        top: -178px;
      }
    }
  }

  &-2 {
    position: relative;
    width: 100%;
    max-width: 100%;
    background-color: #EFF4FF;
    padding: 10rem 0;

    .stripe {

      &_right2 {
        top: -178px;
      }
    }
  }

  #pr-top.stripe_left {
    top: -110px;
  }

  #pr-top.stripe_right2 {
    top: 607px;
  }

  &_left2{
    position: absolute;
    bottom: -17rem;
    left: -15rem;
    z-index: -1;
  }

  &_right2{
    position: absolute;
    top: 0;
    right: 0;
  }

  &_left3{
    position: absolute;
    bottom: -17rem;
    right: -20rem;
    z-index: -1;
  }

  &_right3{
    position: absolute;
    top: 10rem;
    left: 0;
  }

  &_content{
    padding: 0 7rem;

    @media only screen and (max-width: 768px) {
      padding: 0 !important;
    }

    .last-p {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 30px;
      color: #FFFFFF;
      text-align: center;
    }

    &-title{
      font-family: 'MADE Dillan';
      font-style: normal;
      font-weight: 400;
      font-size: 2.5rem;
      line-height: 1.3;
      text-align: center;
      color: #0058A5;
      margin-bottom: 2rem;

      @media only screen and (max-width: 600px) {
        margin-bottom: .5rem;
        padding-bottom: 0 !important;
      }

      &-2 {
        font-family: 'MADE Dillan';
        font-style: normal;
        font-weight: 200;
        line-height: 0;
        text-align: center;
        color: #FFFFFF;
        margin-bottom: 4rem;
      }

      &-3 {
        font-family: 'MADE Dillan';
        font-style: normal;
        font-weight: 800;
        text-align: center;
        color: #009E9B;
        opacity: 51%;
        font-size: 25px;
      }
    }

    &-p{
      text-align: center;
      margin-bottom: 4rem !important;

      &-2 {
        font-family: 'Playfair Display';
        font-style: normal;
        font-weight: 400;
        line-height: 30px;
        text-align: center;
        color: #FFFFFF;
        margin-bottom: 4rem;
      }
    }

    &-button{
      display: flex;
      justify-content: center;
      margin: 0 auto;
    }

    &-chauffagiste77 {

      .row {
        justify-content: center;
        align-items: center;
        padding-bottom: 110px;
      }

      img {
        width: 100%;
      }

      &-title {
        color: #009E9B;
        opacity: 51%;
        font-size: 25px;
        font-weight: 800;
        text-align: center;
        padding-top: 50px;
        padding-bottom: 150px;
      }

      h3 {
        color: #009E9B;
        opacity: 51%;
        font-size: 40px;
        line-height: 48px;
        font-weight: 700;
        text-align: left;
        padding-bottom: 10px;
      }

      p {
        color: #009E9B;
        opacity: 51%;
        font-size: 16px;
        font-weight: 400;
        line-height: 28px;
      }

      &-button {
        display: flex;
        justify-content: flex-start;
      }
    }
  }

  .p80-left {
    padding: 80px 80px 80px 20;
  }

  .p80-right {
    padding: 80px 20px 80px 80px;
  }

  &_left{
    position: absolute;
    top: 0;
    left: 0;

    @media only screen and (max-width: 1400px) {
      display: none;
    }
  }

  &_right{
    width: 26rem;
    height: 27rem;
    position: absolute;
    bottom: -6rem;
    right: 0;
    z-index: -1;
  }
}

.wave-center {
  display: flex;
  justify-content: center;
}

.bg-white-br {
  background-color: #FFFFFF;
  border-radius: 20px;
  padding: 50px 150px;

  p {
    background: linear-gradient(90deg, #009E9B 0%, #14AE9B 31.25%, #28BD9C 53.65%, #47D59C 75.52%, #4FDB9C 90.63%, #5BE39C 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 17px;
    line-height: 23px;
    text-align: center;
    font-weight: 400;
  }
}

.one{
  padding: 8rem 0;
  background-color: #EFF4FF;
  z-index: 100;

  &_content{

    &-images{
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 5rem;
      position: relative;

      &--img{
        width: 26rem;
        max-width: 100%;
        height: 17.2rem;
        border-radius: 2rem;
        z-index: 0;
      }

      &--img1{
        width: 26rem;
        max-width: 100%;
        height: 17.2rem;
        border-radius: 2rem;
        margin-bottom: 10rem;
      }

      &--img2{
        width: 26rem;
        max-width: 100%;
        height: 17.2rem;
        border-radius: 2rem;
        margin-top: 10rem;
      }
    }

    &-image2{
      position: absolute;
      top: 30%;
      left: 25%;
      right: 25%;

      @media only screen and (max-width: 1200px) {
        position: relative;
        top: 2%;
        left: 0;
        right: 0;
       }
    }


    &-image{
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: -15rem;

      &--img{
        width: 42.5rem;
        max-width: 100%;
        height: 29rem;
        border-radius: 2rem;
        z-index: 10;
      }
    }

  }

  &_cards{
    margin-top: 10rem;
    position: relative;

    &-left{

      &--img{
        position: absolute;
        top: -15rem;
        left: 0;
      }

      &--img2{
        width: 75rem;
        max-width: 100%;
        position: absolute;
        margin: auto;
        top: 10%;
        left: 0;
        right: 0;
        opacity: 0.3;
        height: -webkit-fill-available;
      }

      &--img3{
        width: 75rem;
        max-width: 100%;
        position: absolute;
        margin: auto;
        top: 65%;
        left: 0;
        right: 0;
        opacity: 0.3;
        z-index: 2;
      }
    }

    &-right{
      z-index: -1;

      &--img{
        position: absolute;
        bottom: -10rem;
        right: -8rem;
      }

      &--img2{
        position: absolute;
        top: 0rem;
        right: -12rem;
      }
    }

    &-content{
      // margin-left: -10rem;
      // margin-right: -10rem;
    }
  }

  &_card{
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #FFFFFF;
    box-shadow: .6rem 0px 6.4rem rgba(0, 0, 0, 0.1);
    border-radius: 1rem;
    padding: 0 1rem;
    padding-top: 3rem;
    padding-bottom: 6rem;
    z-index: 10;
    height: 100%;

    &-icon{
      margin: 0 auto;
      margin-bottom: 1rem;

      &--img{
        width: 6rem;
        max-width: 100%;
        height: 6rem;
      }
    }

    &-title{
      font-family: 'MADE Dillan';
      font-style: normal;
      font-weight: 400;
      font-size: 2.5rem;
      line-height: 1.3;
      text-align: center;
      color: #0058A5;
      margin-bottom: 2rem;

      @media only screen and (max-width: 768px) {
        margin-top: 1rem;
      }
    }

    &-p{
      font-family: 'DM Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 1.5rem;
      line-height: 1.2;
      text-align: center;
      color: #0058A5;
      padding: 0 1rem;
    }
  }

  &_partners{
    padding-top: 14rem;
    padding-bottom: 7rem;

    &-content{
      margin-bottom: 7rem;
    }

    &-image{
      margin: 0 2rem;

      &--img{
        width: 35rem;
        max-width: 100%;
        height: 8.5rem;
        margin: 1rem 0;
      }
    }
  }

  &_nous{
    padding-top: 14rem;
    padding-bottom: 4rem;
    position: relative;

    &-left{
      z-index: -1;

      &--img{
          z-index: 0;
          position: absolute;
          top: -10rem;
          left: -10rem;
      }
    }

    &-image{
      z-index: 10;
      position: relative;

      &--img{
        width: 55.3rem;
        max-width: 100%;
        border-radius: 2rem;
        z-index: 10;
        margin: 1rem 0;
      }
    }
  }
}

.p-blue {

  p {
    font-weight: 400;
    font-size: 18px;
    line-height: 30px;
    color: #0058A5;
  }

  h2 {
    font-weight: 400;
    font-size: 25px;
    color: #009E9B;
    opacity: 51%;
    padding-bottom: 50px;
  }

  .stripe_content-button {
    position: relative;
    top: 136px;
  }
}

.fluid-container {
  padding: 0 200px;
}

.to-hide-1200{

  @media only screen and (max-width: 1200px) {
    display: none;
   }
}

.services2{
  padding: 15rem 25rem;
  position: relative;

  @media only screen and (max-width: 1800px) {
    padding: 10rem 15rem;
  }
  
  @media only screen and (max-width: 1500px) {
    padding: 10rem 10rem;
  }

  @media only screen and (max-width: 1200px) {
    padding: 10rem 2rem;
  }
}

.services{
  padding: 10rem 25rem !important;
  position: relative;

  @media only screen and (max-width: 1800px) {
    padding: 10rem !important;
  }

  @media only screen and (max-width: 1600px) {
    padding: 10rem .5rem !important;
  }


  &_bg{
    background-image: url("/assets/img/6.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    max-width: 100%;
  }

  &_content{
    padding-left: 5rem;

    @media only screen and (max-width: 1500px) {
      padding: 0;
    }
  }
  }

  .margin-1200{

    @media only screen and (max-width: 1200px) {
      margin-right: 7rem !important;
    }

    @media only screen and (max-width: 990px) {
      margin-right: 0 !important;
    }
  }

  .margin-l-1200{

    @media only screen and (max-width: 1400px) {
      margin-left: 7rem !important;
    }

    @media only screen and (max-width: 990px) {
      margin-left: 0 !important;
    }
  }


.bg-blue{
  background-color: #EFF4FF !important;
}

.p-7{
  padding: 7rem 0 !important;

  @media only screen and (max-width: 768px) {
    padding: 0 !important;
    padding-top: 1rem !important;
  }
}

.h-0{
  height: auto !important;
}

.h-1{
  height: 15.5rem !important;
}

.h-2{
  width: 21rem;
  max-width: 100%;
  height: 20rem;
}

.pb-14{
  padding-bottom: 20rem !important;
}

.m-l-r{
  margin-left: -15rem !important;
  margin-right: -15rem !important;
}

.p-l-r{
  padding-left: 20rem !important;
  padding-right: 20rem !important;

  @media only screen and (max-width: 1200px) {
    padding-left: 10rem !important;
    padding-right: 10rem !important;
  }

  @media only screen and (max-width: 990px) {
    padding-left: 5rem !important;
    padding-right: 5rem !important;
  }

  @media only screen and (max-width: 768px) {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }
}

.ml-left{
  padding-left: 15rem !important;
}

.icon {
  display: flex;
  justify-content: center;

  .semi-line {
    position: relative;
    top: 37px;
  }

  .icon-col {
    display: flex;
    flex-direction: column;
    align-items: center;

    img {
      padding-bottom: 30px;
    }

    h3 {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 30px;
      color: #FFFFFF;
      text-align: center;
      padding-bottom: 30px;
    }

    p {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 28px;
      color: #FFFFFF;
      text-align: center;
    }
  }
}


.footer{
  background-image: url("/assets/img/footer-bg.png");
  background-position: center top;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  width: 100%;
  max-width: 100%;
  background-color: #EFF4FF;
  padding: 10rem 0;

  &-2 {
    background-image: url("/assets/img/footer-bg.png");
    background-position: center top;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    width: 100%;
    max-width: 100%;
    background-color: #fff;
    padding: 10rem 0;
  }
  
  &_header{
    margin-top: 5rem;

    &-title{
      font-family: 'Maximum Impact';
      font-style: normal;
      font-weight: 500;
      font-size: 4.2rem;
      line-height: 1.4;
      text-align: center;
      color: #0058A5;
    }

    &-p{
      font-family: 'Maximum Impact';
      font-style: normal;
      font-weight: 500;
      font-size: 2rem;
      line-height: 1.3;
      text-align: center;
      color: #0058A5;
    }
  }

  &_content{
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;

    @media only screen and (max-width: 768px) {
      justify-content: center;
      align-items: center;
    }

    &-social{
      display: flex;
      align-items: center;
      justify-content: center !important;

      &--p{
        font-family: 'Maximum Impact';
        font-style: normal;
        font-weight: 500;
        font-size: 2rem;
        line-height: 1.3;
        text-align: center;
        color: #EFF4FF;
        margin: 0 2rem;
      }

      &--icon{
          font-family: "Font Awesome 5 Free";
          font-size: 2rem;
          color: #fff !important;
          margin: 0 1rem;
          width: 3.3rem;
          height: 3.3rem;
          max-width: 100%;  
      }
    }

    &-bottom{
      margin-top: 4rem;

      &--title{
        font-family: 'Maximum Impact';
        font-style: normal;
        font-weight: 500;
        font-size: 3rem;
        line-height: 1.4;
        text-align: center;
        color: #EFF4FF;
        margin-bottom: 2rem;
      }
    }

    &-title{
      font-family: 'Maximum Impact';
      font-style: normal;
      font-weight: 500;
      font-size: 3rem;
      line-height: 1.4;
      text-align: center; 
      color: #EFF4FF;
      margin-top: 4rem !important;
    }

    &-ul{
      list-style: none;
      margin-top: 2rem;
    }

    &-li{
      font-family: 'Maximum Impact';
      font-style: normal;
      font-weight: 500;
      font-size: 1.9rem;
      line-height: 1.4;
      color: #EFF4FF;
      margin: 2rem 0;

      &--link{
        font-family: 'Maximum Impact';
        font-style: normal;
        font-weight: 500;
        font-size: 1.9rem;
        line-height: 1.4;
        color: #EFF4FF;
        display: inline;

        &:hover{
          text-decoration: none;
          cursor: pointer;
        }
      }
    }
  }
}

.banner{

  &-1{
    background-image: url("/assets/img/banner1.png");
    background-position: center bottom;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    width: 100%;
    max-width: 100%;
    height: auto;
    background-color: #EFF4FF !important;
  }

  &-2{
    background-image: url("/assets/img/banner2.1.png");
    background-position: center bottom;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    width: 100%;
    max-width: 100%;
    background-color: transparent;
    z-index: 0;
    
  }

  &-3{
    background-image: url("/assets/img/3-bg.1.png");
    background-position: center bottom;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    width: 100%;
    max-width: 100%;
    background-color: transparent;
    z-index: 10;
  }

  &-4{
    background-image: url("/assets/img/banner4.1.png");
    background-position: center bottom;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    width: 100%;
    max-width: 100%;
    background-color: transparent;
    z-index: 10;
  }

  &-5{
    background-image: url("/assets/img/banner5.png");
    background-position: center bottom;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    width: 100%;
    max-width: 100%;
    background-color: #eff4ff;
    z-index: 10;
  }

  &-6{
    background-image: url("/assets/img/banner6.png");
    background-position: center bottom;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    width: 100%;
    max-width: 100%;
    background-color: #eff4ff;
    z-index: 10;
  }

  &-contact {
    background-image: url(../img/contact-banner.3.png);
    background-position: center bottom;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    width: 100%;
    max-width: 100%;
    background-color: transparent;
    z-index: 10;
  }

  &-professional{
    background-image: url("/assets/img/professional-banner.1.png");
    background-position: center bottom;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    width: 100%;
    max-width: 100%;
    background-color: transparent;
    z-index: 10;
  }

  &-allinaz{
    background-image: url("/assets/img/allinaz-banner.png");
    background-position: center bottom;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    width: 100%;
    max-width: 100%;
    background-color: transparent;
    z-index: 10;
  }

  &-particuller{
    background-image: url("/assets/img/particuller-banner.png");
    background-position: center bottom;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    width: 100%;
    max-width: 100%;
    background-color: transparent;
    z-index: 10;
  }

  &-devis{
    background-image: url("/assets/img/devis-banner.png");
    background-position: center bottom;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    width: 100%;
    max-width: 100%;
    background-color: transparent;
    z-index: 10;
  }


  // &::before {
  //     content: "";
  //     position: absolute;
  //     top: 0;
  //     left: 0;
  //     width: 100%;
  //     height: 100%;
  //     background: #000;
  //     opacity: 0.7;
  //     z-index: -1;
  //     transition: 0.5s;
  // }

  &_content{

    &-2 {
      padding: 30rem 0;
    }

    padding: 18rem 0;

    &-title{
      font-family: 'MADE Dillan';
      font-style: normal;
      font-weight: 400;
      font-size: 6.8rem;
      line-height: 1.4;
      text-align: center;
      background: linear-gradient(90deg, #009E9B 0%, #14AE9B 31.25%, #28BD9C 53.65%, #47D59C 75.52%, #4FDB9C 90.63%, #5BE39C 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;

      
      @media only screen and (max-width: 990px) {
        font-size: 4.8rem;
      }

      @media only screen and (max-width: 600px) {
        font-size: 3.4rem;
      }
    }
  }
}


.to-space{

  @media only screen and (max-width: 990px) {
    margin: 1rem 0 !important;
  }
}

.to-hide-990{

  @media only screen and (max-width: 990px) {
    display: none !important;
  }
}

.p-t-10{
  padding-top: 15rem !important;
}

.color-primary{
  color: #10AB9B !important;
}

.btn-active{
  color: #fff !important;
  background-color: #10AB9B !important;
}

.donnier{
  width: auto !important;
  height: 17rem !important;
}

.r-20{
  right: 10% !important;
}

.wave{
  background-image: url("/assets/img/wave.png");
  background-position: center bottom;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  max-width: 100%;
  background-color: #EFF4FF;
  padding: 26rem 0;
  padding-bottom: 12rem;
  margin-top: -30rem;
  z-index: -1;
  position: relative;

  @media only screen and (max-width: 990px) {
    display: none;
  }

  &_content{
    padding: 0 15rem;

    @media only screen and (max-width: 1200px) {
      padding: 0 1rem;
    }
    
    &-top{
      margin-top: -5rem!important;
      margin-left: -19rem!important;

      &--title{
        font-family: 'Maximum Impact';
        font-style: normal;
        font-weight: 500;
        font-size: 2rem;
        line-height: 1.4;
        margin-top: 2rem;
        color: #0058A5;

        &:hover{
          text-decoration: none;
        }

      }

      &--link{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-decoration: none;
        cursor: pointer;
      }

      &--img{
        width: auto;
        max-width: 100%;
        height: 13.4rem;
      }
    }

    &-top2{
      margin-top: 6rem!important;
      margin-right: -6rem!important;
    }

    &-bottom{
      margin-bottom: -10rem!important;
      margin-left: -9rem;
    }

    &-bottom2{        
      margin-top: 12rem!important;
      margin-right: -19rem!important;

      &--img{
        width: auto;
        max-width: 100%;
        height: 13.4rem;
      }
    }
  }
}

.inline{
  padding-top: 5rem;
  padding-bottom: 9rem;

  &_image{

    @media only screen and (max-width: 1800px) {
      height: 100%;
    }

    @media only screen and (max-width: 990px) {
      margin: 4rem 1rem;
    }

    &-img{
      width: 100%;
      max-width: 100%;
      height: 40rem;
      object-fit: cover;

      @media only screen and (max-width: 1800px) {
        height: 100%;
      }
    }
  }
}


.travaux{
  padding-top: 0rem;
  padding-bottom: 14rem;

  &_padding{
    padding: 2rem 0;
  }

  &_link{
    background: transparent;
    outline: none;
    border: none;

    &:hover{
      text-decoration: none;
      cursor: pointer;
     }
  }

  &_button{

    &-image{
      width: 100%;
      max-width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      &--img{
        width: 20rem;
        max-width: 100%;
      }
    }

    &-title{
      font-family: 'Maximum Impact';
      font-style: normal;
      font-weight: 500;
      font-size: 2.9rem;
      line-height: 1.4;
      text-align: center; 
      color: #0058A5;
    }
  }

  &_card{
    width: 100%;
    max-width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.16);
    box-shadow: 6px 0px 64px rgba(0, 0, 0, 0.1);
    border-radius: 1rem;

    &-image{
      width: 100%;
      max-width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #FFFFFF;
      box-shadow: .6rem 0px 6.4rem rgba(0, 0, 0, 0.1);
      border-radius: 1rem;
      height: 100%;
      padding: 4rem;

      &--img{
        width: 100%;
        max-width: 100%;
      }
    }

    &-content{
      width: 100%;
      max-width: 100%;
      background: rgba(255, 255, 255, 0.16);
      border-radius: 1rem;
      height: 100%;
      padding: 4rem 8rem;

      &-ul{
        list-style: none;
      }

      &-title{
        font-family: 'Playfair Display';
        font-style: normal;
        font-weight: 400;
        font-size: 2.5rem;
        line-height: 1.4;
        color: #0058A5;
      }

      &-p{
        font-family: 'Playfair Display';
        font-style: normal;
        font-weight: 400;
        font-size: 1.6rem;
        line-height: 1.4;
        color: #0058A5;
      }
    }
  }
}


.location-before {

  &::before{
    content: "\f3c5";
    font-family: "Font Awesome 5 Free";
    font-size: 2rem;
    margin-right: 1rem;
    color: #14AE9B !important;
  }
}

.dot-before{

  &::before{
    content: "\f111";
    font-family: "Font Awesome 5 Free";
    font-size: .8rem;
    margin-right: 1rem;
    margin-bottom: 1rem;
  }
}

.gallery{
    padding-bottom: 4rem;

  &_image{

    &-img{
      width: 100%;  
      max-width: 100%;
      height: 45rem;
      border-radius: 2rem;
      margin: 1rem .5rem;
    }
  }
}

.devis, .contact {
  padding: 5rem 0;

  &_header{
    font-family: 'MADE Dillan';
    font-style: normal;
    font-weight: 400;
    font-size: 6.8rem;
    line-height: 1.5;
    text-align: center;
    background: linear-gradient(90deg, #009E9B 0%, #14AE9B 31.25%, #28BD9C 53.65%, #47D59C 75.52%, #4FDB9C 90.63%, #5BE39C 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    margin-bottom: 3rem;

    @media only screen and (max-width: 990px) {
      font-size: 4.8rem;
    }

    @media only screen and (max-width: 600px) {
      font-size: 3.4rem;
    }
  }

  &_content {

    &-num {
      font-size: 45px;
      font-weight: 500;
      line-height: 52.29px;
      text-align: center;

      a {
        color: var(--blue);

        &:hover {
          text-decoration: none;
          color: #009E9B;
        }
      }
    }

    &-adresse, &-email {
      font-size: 31px;
      color: #0058A5;
      font-weight: 500;
      line-height: 38.6px;
      text-align: center;

      a {
        color: #0058A5;

        &:hover {
          text-decoration: none;
          color: #009E9B;
        }
      }
    }
  }

  &_buttons{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }

  &_button{
    position: relative;
    margin: 0 2rem;

    &-image{
      width: 24rem;
      max-width: 100%;
      height: 22rem;
      padding: 4rem;
      background: #FFFFFF;
      box-shadow: .6rem 0px 6.4rem rgba(0, 0, 0, 0.1);
      border-radius: 1rem;

      &--img{
        width: 20rem;
        height: 22rem;
        max-width: 100%;
      }
    }
  }

  &_input{
    margin-top: 4rem;

    &-label{
      display: flex;
      justify-content: center;
      font-family: 'MADE Dillan';
      font-style: normal;
      font-weight: 400;
      font-size: 3.3rem;
      line-height: 1.4;
      text-align: center !important;
      background: linear-gradient(90deg, #009E9B 0%, #14AE9B 31.25%, #28BD9C 53.65%, #47D59C 75.52%, #4FDB9C 90.63%, #5BE39C 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      margin: 0 auto;
      margin-bottom: 2rem;
    }

    &-field{
      font-family: 'Maximum Impact';
      font-style: normal;
      font-weight: 500;
      font-size: 3.3rem;
      line-height: 1.4;
      text-align: center;
      color: #333;
      width: 100%;
      max-width: 100%;
      padding: 1rem 0;
      background: #FFFFFF;
      box-shadow: .6rem 0px 6.4rem rgba(0, 0, 0, 0.1);
      border-radius: 1rem;
      border-color: none !important;
      outline: none;
    }

    &-select{
      font-family: 'Maximum Impact';
      font-style: normal;
      font-weight: 500;
      font-size: 3.3rem;
      line-height: 1.4;
      text-align: center;
      color: #9F9F9F;
      width: 100%;
      max-width: 100%;
      padding: 1rem 0;
      background: #FFFFFF;
      box-shadow: .6rem 0px 6.4rem rgba(0, 0, 0, 0.1);
      border-radius: 1rem;
      border-color: none !important;
      outline: none;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      background: url("/assets/img/arrow.svg") no-repeat #fff;
      background-size: 2%;
      background-position: 98%;
      
      &::-ms-expand {
        display: none;
      }
    }

    &-button{
      font-family: 'Maximum Impact';
      font-style: normal;
      font-weight: 500;
      font-size: 2.5rem;
      line-height: 1.3;
      padding: 2rem 4rem;
      text-align: center;
      color: #FFFFFF;
      outline: none;
      background: linear-gradient(90deg, #009E9B 0%, #14AE9B 31.25%, #28BD9C 53.65%, #47D59C 75.52%, #4FDB9C 90.63%, #5BE39C 100%);
      border-radius: 3.5rem;
    }
  }
}

.success{
  padding: 5rem 0;
}

.partners_flex{
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: nowrap;

  @media only screen and (max-width: 990px) {
    justify-content: center;
  }
}

.partners_image-img{
  margin: 1rem 0;
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #E3E3E3;
  opacity: 1; /* Firefox */
}

.backIcon{
  background-color: #009E9B;
  display: inline;
  margin-left: 2rem;

  &-i{
  background-color: #009E9B;
  color: #fff;
  font-size: 3.6rem;
  padding: 2rem 3rem;
  border-radius: 1rem;
  }
}

.img-carousel {

  input[type=radio] {
    display: none;
  }

  .card {
    position: absolute;
    border: none;
    width: 60%;
    left: 0;
    right: 0;
    margin: auto;
    transition: transform .4s ease;
    border-radius: 3.4rem !important;
    background-color: #fff !important;
    cursor: pointer;
  }

  .container {
    width: 100%;
    max-width: 800px;
    max-height: 600px;
    height: 100%;
    transform-style: preserve-3d;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }

  .cards {
    position: relative;
    width: 100%;
    height: 100%;
    margin-bottom: 20px;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  #img-first:checked ~ .cards #img-3, #img-second:checked ~ .cards #img-1, #img-third:checked ~ .cards #img-2 {
    transform: translatex(-40%) scale(0.8);
    opacity: .4;
    z-index: 0;
    top: -82px;
    left: -15%;
  }

  #img-first:checked ~ .cards #img-2, #img-second:checked ~ .cards #img-3, #img-third:checked ~ .cards #img-1 {
    transform: translatex(40%) scale(0.8);
    opacity: .4;
    z-index: 0;
    top: -82px;
    left: 15%;
  }

  #img-first:checked ~ .cards #img-1, #img-second:checked ~ .cards #img-2, #img-third:checked ~ .cards #img-3 {
    transform: translatex(0) scale(1);
    opacity: 1;
    z-index: 1;
  }
}


.pad30 {
  padding: 30px 0 !important;
}

.mt-33 {
  margin-top: 33rem !important;
}

.img-carousel2 {

  input[type=radio] {
    display: none;
  }

  .card {
    position: absolute;
    border: none;
    width: 60%;
    left: 0;
    right: 0;
    margin: auto;
    transition: transform .4s ease;
    border-radius: 3.4rem !important;
    background-color: #fff !important;
    cursor: pointer;
  }

  .container {
    width: 100%;
    max-width: 800px;
    max-height: 600px;
    height: 100%;
    transform-style: preserve-3d;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }

  .cards {
    position: relative;
    width: 100%;
    height: 100%;
    margin-bottom: 20px;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  #img-fifth:checked ~ .cards #img-4, #img-fourth:checked ~ .cards #img-6, #img-sixth:checked ~ .cards #img-5 {
    transform: translatex(-40%) scale(0.6);
    opacity: .4;
    z-index: 0;
    top: -82px;
    left: -20%;
  }

  #img-fifth:checked ~ .cards #img-6, #img-fourth:checked ~ .cards #img-5, #img-sixth:checked ~ .cards #img-4 {
    transform: translatex(40%) scale(0.6);
    opacity: .4;
    z-index: 0;
    top: -82px;
    left: -25%;
  }

  #img-fifth:checked ~ .cards #img-5, #img-fourth:checked ~ .cards #img-4, #img-sixth:checked ~ .cards #img-6 {
    transform: translatex(0) scale(0.9);
    opacity: 1;
    z-index: 1;
    top: 50px;
    left: -25%;
  }
}

.img-carousel3 {

  input[type=radio] {
    display: none;
  }

  .card {
    position: absolute;
    border: none;
    width: 60%;
    left: 0;
    right: 0;
    margin: auto;
    transition: transform .4s ease;
    border-radius: 3.4rem !important;
    background-color: #fff !important;
    cursor: pointer;
  }

  .container {
    width: 100%;
    max-width: 800px;
    max-height: 600px;
    height: 100%;
    transform-style: preserve-3d;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }

  .cards {
    position: relative;
    width: 100%;
    height: 100%;
    margin-bottom: 20px;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  #img-eighth:checked ~ .cards #img-7, #img-nineth:checked ~ .cards #img-8, #img-seventh:checked ~ .cards #img-9 {
    transform: translatex(-40%) scale(0.7);
    opacity: .4;
    z-index: 0;
    top: -100px;
    left: -25%;
  }

  #img-eighth:checked ~ .cards #img-9, #img-nineth:checked ~ .cards #img-7, #img-seventh:checked ~ .cards #img-8 {
    transform: translatex(40%) scale(0.7);
    opacity: .4;
    z-index: 0;
    top: -100px;
    left: 25%;
  }

  #img-eighth:checked ~ .cards #img-8, #img-nineth:checked ~ .cards #img-9, #img-seventh:checked ~ .cards #img-7 {
    transform: translatex(0) scale(1.3);
    opacity: 1;
    z-index: 1;
  }
}

.img-carousel4 {

  input[type=radio] {
    display: none;
  }

  .card {
    position: absolute;
    border: none;
    width: 60%;
    left: 0;
    right: 0;
    margin: auto;
    transition: transform .4s ease;
    border-radius: 3.4rem !important;
    background-color: #fff !important;
    cursor: pointer;
  }

  .container {
    width: 100%;
    max-width: 800px;
    max-height: 600px;
    height: 100%;
    transform-style: preserve-3d;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }

  .cards {
    position: relative;
    width: 100%;
    height: 100%;
    margin-bottom: 20px;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  #img-eleventh:checked ~ .cards #img-10, #img-tenth:checked ~ .cards #img-12, #img-twelveth:checked ~ .cards #img-11 {
    transform: translateY(-40%) scale(0.6);
    opacity: .4;
    z-index: 0;
    top: -41px;
  }

  #img-eleventh:checked ~ .cards #img-12, #img-tenth:checked ~ .cards #img-11, #img-twelveth:checked ~ .cards #img-10 {
    transform: translateY(40%) scale(0.6);
    opacity: .4;
    z-index: 0;
    top: 41px;
  }

  #img-eleventh:checked ~ .cards #img-11, #img-tenth:checked ~ .cards #img-10, #img-twelveth:checked ~ .cards #img-12 {
    transform: translateY(0) scale(0.9);
    opacity: 1;
    z-index: 1;
  }
}

.pr-230 {
  padding-right: 230px!important;
}

.pl-150 {
  padding-left: 150px!important;
}

.pt-19 {
  padding-top: 19rem!important;
}

.w-80 {
  width: 80%!important;
}

.l--80 {
  left: -80%!important;
}

.no-mw {
  max-width: none!important;
}

.ml--90 {
  margin-left: -90%!important;
}

.pl-70 {
  padding-left: 70px!important;
}

.pt-34 {
  padding-top: 34rem!important;
}

.pt-7rem {
  padding-top: 7rem;
}

.ml-10 {
  margin-left: 10%!important;
}

.plr-60 {
  padding-left: 60px!important;
  padding-right: 60px!important;
}

.ml-left {
  padding-left: 15rem!important;
}

.l--60 {
  left: -60%!important;
}

.logo-copyright {
  font-size: 55px;
  color: #003781;

  small {
    text-align: center;
    font-size: 15px;
    color: #003781;
  }
}

.pad100 {
  padding: 100px 0;
}

.pad10 {
  padding: 10px 0;
}

.pad20 {
  padding: 20px 0;
}

.ml--30 {
  margin-left: -30%!important;
}

.no-pt {
  padding-top: 0 !important;
}

.pb-50 {
  padding-bottom: 0;
}

@media (max-width: 1500px) {

  .img-carousel3  {
  
    .card {
      width: 60%;
    }
  }

  .one {
  
    &_nous {
    
      &-image {
        
        &--img {
          position: relative;
          left: 25%;
          width: 35.3rem;
        }
      }
    }
  }

  .contact {
    
    &_header {
      font-size: 4.8rem;
    }

    &_content {
      
      &-num {
        font-size: 35px;
      }

      &-adresse, &-email {
        font-size: 21px;
      }
    }
  }
}

@media (max-width: 1400px) {

  .one {
  
    &_nous {
    
      &-image {
        
        &--img {
          left: 15%;
        }
      }
    }
  }
}

@media (max-width: 1300px) {

  .wave {
    
    &_content {
      padding: 0 15rem !important;
    }
  }

  .one {
  
    &_nous {
    
      &-image {
        
        &--img {
          left: 0%;
        }
      }
    }
  }

  .pr-230 {
    padding-right: 90px !important;
  }

  .p-125 {
    padding: 1rem !important;
  }
}

@media (max-width: 1200px) {
  
  .contact {
    
    &_header {
      font-size: 3.8rem;
    }
  }
}

@media (max-width: 990px) {

  .banner-2, .banner-3, .banner-4 {
    background-color: #EFF4FF;
  }

  .inline {

    &_image {
      margin: 0;
    }
  }
}

@media (max-width: 991px) {

  .pl-150 {
    padding-left: 15px !important;
  }
  
  .pad100 {
    padding: 0;
  }

  .img-carousel3  {
  
    .card {
      width: 40%;
    }
  }
  
  .contact {
    
    &_header {
      padding-top: 60px;
    }
  }

  .services {

    &_content {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin: 0px 60px;
    }
  }

  .pr-230 {
    padding-right: 15px !important;
    padding-bottom: 50px;
  }
  
  .margin-l-1200 {
    margin-left: 60px !important;
  }

  .one {
  
    &_nous {
      padding-top: 0;

      .mt-3 {
        display: flex;
        justify-content: center;
      }
    
      &-image {
        display: flex;
        align-items: center;
        justify-content: center;

        &--img {
          left: auto !important;
        }
      }
    }

    &_content {

      &-images {
        margin-top: 10rem;
      }
    }

    &_partners {
      padding: 3rem;

      &-image {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  .ml-left {
    padding-left: 15px !important;
    padding-bottom: 50px;
  }

  .l--80, .l--60 {
    left: auto !important;
  }

  .ml--90 {
    margin-left: 0 !important;
  }

  .col-md-6, .col-md-5, .col-md-1, .col-md-4, .col-md-7, .col-sm-4, .col-sm-8 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .pl-70 {
    padding-left: 15px !important;
  }

  .services {
    padding: 0 !important;
  }

  .container#marques-partners {
    max-width: 100% !important;
  }

  .img-carousel2 {
    position: relative;
    left: 8%;
    padding-bottom: 380px;
  }

  .pb-50 {
    padding-bottom: 50px;
  }

  .menu {

    &_image {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding-bottom: 40px; 
    }
  }

  .inline {

    .col-md-4 {
      display: flex;
      justify-content: center;
    }

    &_image {
      width: 70%;
    }
  }

  .footer {

    &_content {
      justify-content: center;
      align-items: center;

      &-li {
        margin: 1rem 0;
      }

      &-ul {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
      }
    }
  }

  .col-md-6#col-50 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .gallery {

    &_image {
      display: flex;
      justify-content: center;

      &-img {
        width: 50%;
      }
    }
  }
}

@media (max-width: 759px) {

  .gallery {

    &_image {

      &-img {
        width: 60%;
      }
    }
  }

  .travaux {
    
    &_card {
      
      &-content {
      
        &-title {
          font-size: 2rem;
        }
      }
    }
  }

  .pl-150 {
    padding-left: 15px !important;
  }

  .img-carousel3  {
  
    .card {
      width: 40%;
    }
  }

  .services {

    &_content {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin: 0px 60px;
    }
  }

  .pr-230 {
    padding-right: 15px !important;
    padding-bottom: 50px;
  }
  
  .margin-l-1200 {
    margin-left: 60px !important;
  }

  .one {
  
    &_nous {
      padding-top: 0;
    
      &-image {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 50px 0;    

        &--img {
          left: auto !important;
        }
      }
    }

    &_content {

      &-images {
        margin-top: 10rem;
      }
    }

    &_partners {

      &-image {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  .ml-left {
    padding-left: 15px !important;
    padding-bottom: 50px;
  }

  .l--80 {
    left: auto !important;
  }

  .ml--90 {
    margin-left: 0 !important;
  }

  .col-md-6, .col-md-5, .col-md-1 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .pl-70 {
    padding-left: 15px !important;
  }

  .services {
    padding: 0 !important;
  }

  .container#marques-partners {
    max-width: 100% !important;
  }

  .footer {
  
    &_content {
    
      &-ul {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
      }

      &-li {
        margin: 1rem 0;
      }
    }
  }

  .slider_bottom-content {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  .img-carousel2 {
    left: 11%;
  }

  .pb-14 {
    padding-bottom: 0 !important;
  }
}

@media (max-width: 687px) {
  
  .ml--30 {
    margin-left: 0 !important;
  }

  .devis {
    
    &_button {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      &-image{
        position: relative;
      }
    }
  }
}

@media (max-width: 467px) {

  .gallery {

    &_image {

      &-img {
        width: 100%;
      }
    }
  }

  .travaux {
    
    &_card {
      
      &-content {
      
        &-title {
          font-size: 1.4rem;
        }

        p {
          font-size: 1.2rem;
        }
      }
    }
  }

  .one {

    &_partners {
      padding-top: 0;
    }

    &_card {
      
      &-title {
        margin-top: 5rem;
      }
    }
  }
  .partners {

    &_flex, &_image {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }

  .w-80 {
    width: 50% !important;
  }

  .img-carousel4, .img-carousel2  {
  
    .card {
      width: 80%;
    }
  }

  .pt-19 {
    padding-top: 9rem !important;
  }

  .img-carousel2 {
    padding-bottom: 237px;
  }


  .img-carousel3 {
    
    .card {
      width: 50%;
    }
  }

  .mt-33 {
    margin-top: 21rem !important;
  }

  .stripe {
    
    &_content {
      
      &-title {
        margin-top: 0;
      }
    }
  }
}


.radio{
  position: absolute;
  top: 0;
  left: 0;
  width: 24rem;
  max-width: 100%;
  height: 22rem;
  z-index: 100;
  visibility: hidden;
}

.radio2{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  max-width: 100%;
  height: 12rem;
  z-index: 100;
  visibility: hidden;
}